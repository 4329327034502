
.policy-claim-in-main {
  font-size: 17px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.claims-main-cover-wrappper {
  width: 95%;
  margin-top: 100px;
}

.show_hide_suminsured {
  display: none;
}

.error {
  color: red;
}

.card-header .active {
  font-weight: bold;
}

.card-header .active:focus {
  text-decoration: none;
}

.letter_div {
  max-width: 1000px !important;
  margin: 2% 15% !important;
}

/* Previous and Next claim Submission Starts------------------------- */
.kyc-input-box select {
  width: 300px;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 14px;
  height: 35px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
  rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

select.form-control.select-input option {
  padding: 8px 15px;
  background-color: aliceblue;
  margin-top: -5px;
}

.row.claim-intimate-forms-cover label {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 3px;
}

.row.claim-intimate-forms-cover .form-control::placeholder {
    color: #999; 
    font-size: 14px;
}

.step_claim {
  border-radius: 6px;
  background-color: #f2f7ff73;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  padding: 10px 17px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.step_claim h5 {
  font-size: 15px;
  margin-bottom: 0px;
  font-family: "Lora", serif;
  font-weight: 600;
}

/* .form-step {
  display: none;
} */

.form-step.active {
  display: block;
}

.footer-buttons {
  margin-top: 20px;
  text-align: right;
}

.footer-buttons button {
  margin-left: 10px;
}

.row.claim-intimate-forms-cover .form-control {
	border: 1px solid #0000001f;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 7px;
	font-size: 14px;
	color: dimgray;
	padding: 8px 10px;
}

.kyc-doc {
  display: flex;
  align-items: center;
  border: 1px solid #69696942;
  /* justify-content: space-between; */
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  margin-top: 18px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.kyc-doc img {
  width: 40px;
  margin-right: 12px;
}

.kyc-doc button img {
  width: 100%;
}

.kyc-doc button {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: right;
  position: absolute;
  right: 12px;
}

/* .kyc-input-box {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 2px dashed #6969694d;
  margin-bottom: 7px;
} */

.submit-kyc-btn {
  margin: 0px 0px;
  margin-bottom: 0px;
}

.kyc-doc-details h5 {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  font-weight: 600 !important;
}

.kyc-doc-details p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.kyc-doc {
  display: flex;
  align-items: center;
  border: 1px solid #69696942;
  /* justify-content: space-between; */
  padding: 10px;
  border-radius: 10px;
  width: 96%;
  margin-top: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.kyc-doc-details p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px; /* Adjust the max-width according to your design */
  display: inline-block; /* Ensures the ellipsis works with inline content */
}

button.add-kyc-btn span img {
  width: 17px;
  height: 16px;
}

.form-claim2-main-wrapper h5 {
  font-size: 15px;
  /* text-align: center; */
  margin-bottom: 13px;
}

button.add-kyc-btn {
  padding: 5px 15px;
  padding-left: 11px;
  border-radius: 5px;
  background: #ebebeb;
  border: none;
  position: relative;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border: 1px solid #6969696b;
}

.form-claim2-main-wrapper h5.header-claim-title {
  font-size: 18px;
  /* text-align: center; */
  margin-bottom: 22px;
  text-align: center;
}

.stepper_main_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 700px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 50px;
}

p.upload-doc-info {

  color: dimgray;
  font-size: 14px;
  padding-top: 10px;
  border-top: 1px solid #69696987;
}

.form-step2-main-cover-wrapper {
  padding-bottom: 10px;
  border-bottom: 2px dashed #69696959;
}
.loading-data-box {
  width: 320px;
}

.upload-kyc-btn-box button span svg {
	margin-right: 6px;
}
.upload-kyc-btn-box button {
	margin-right: 15px;
}
.add-kyc-btn-box input {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 6px;
}
.claim-document-main-wrapper {
	width: 95%;
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.upload-document-btn-box button span img {
  width: 25px;
  margin-right: 5px;
}

.upload-document-btn-box button {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 5px 14px;
  padding-left: 5px;
  margin-top: -17px;
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.upload-document-boxlist-cover {
  border-radius: 10px;
  padding: 13px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: white;
  height: 100%;
}

.upload-document-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 25px;
}

.upload-document-btn-box {
  margin-top: -12px;
}

.upload-kyc-btn-box button {
  padding: 4px 10px;
  border-radius: 7px;
  background-color: aliceblue;
  font-size: 14px;
  position: relative;
}

.upload-document-btn-box button {
  background: #357ef9;
  color: white;
  font-size: 13px;
}

.uploaded-documents-list {
  margin: 10px 5px;
  padding: 5px 10px;
  border: 1px solid #69696905;
  border-radius: 9px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.uploaded-documents-list h6 {
  margin-bottom: 0px;
  font-size: 13px;
  color: dimgray;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px; /* Adjust the width as needed */
}
.upload-document-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 25px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #6969692e;
}

.ok-btn button {
  padding: 5px 25px;
  border-radius: 10px;
  border: none;
  width: 36%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center !important;
  margin-top: 22px;
  background-color: #7b7bd7;
  color: white;
  font-weight: 800;
}

.uploaded-documents-list button {
	border: none;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
	background-color: #8574e7;
	border: 1px solid #6969692e;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.uploaded-documents-list button svg {
	color: white;
	width: 28px;
	height: 27px;
}

.upload-document-btn-box button svg {
	margin: 0 7px;
}

.uploaded-documents-list {
  margin: 10px 5px;
  padding: 4px 10px;
  border: 1px solid #69696905;
  border-radius: 9px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
  rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-documents-list-box.height-style {
  max-height: 110px;
  overflow-y: scroll;
}

.uploaded-documents-list-box.height-style::-webkit-scrollbar {
  width: 8px; 
}

.uploaded-documents-list-box.height-style::-webkit-scrollbar-thumb {
  background-color: lightblue; 
  border-radius: 5px;
}

.upload-document-list-header h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 175px;
  display: inline-block;
  font-size: 13px;
}

.document-upload-row-cover {
  padding-top: 3px;
  /* border-top: 1px dashed dimgray; */
  margin-top: 0px;
}

.success-data-animation {
  width: 59%;
  margin: 0 auto;
  margin-bottom: -27px;
}

.footer-buttons button {
  border-radius: 5px;
  padding: 5px 25px;
  background: #8574e7;
  color: white;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    height: 40px;
}

.footer-buttons {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.step_claim.active-step-claim {
  border: 1px solid dimgray;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #6e36c9;
  color: white;
  transform: scale(1.2);
}

.upload-document-btn-box button {
  position: relative;
}

.claim_exist_modal_cover-wrapper h5 {
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #000000e6;
}
.claim_exist-icon_cover img {
  width: 18%;
  margin: 0 auto;
  display: flex;
}
.close-compare-modal {
  float: right;
}
.close-compare-modal svg {
  font-size: 25px;
  color: #5ebbd9;
  margin-top: -4px;
}

.disable-step-claim {
  opacity: 0.5;
  pointer-events: none; 
  cursor: not-allowed;
}

.kyc-doc-input {
  font-size: 13px;
}
.home-claim-kyc-header-btn button {
  padding: 6px 15px;
  border: 1px solid #69696947;
  border-radius: 8px;
  font-size: 14px;
  background-color: #6696e7;
  color: white;
  font-weight: 700;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.claim-kyc-modal-btn {
  position: absolute;
  right: 11px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
}

.form-claim2-main-wrapper {
  margin-top: 30px;
  width: 90%;
  margin: 0 auto;
  margin-top: 35px;
}

.claim-kyc-modal-btn svg {
  font-size: 26px;
}

.claim-documentlist-main-cover-wrapper {
  margin-top: 24px;
}

button.delete-kyc-doc-btn {
  padding: 1px 8px;
  border-radius: 5px;
  color: white;
  background-color: #ef3939;
  font-weight: 500;
  border: none;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.home-claim-kyc-header-btn button span {
  margin-right: 7px;
}

.delete-modal-btns button {
  border-radius: 5px;
  padding: 5px 11px;
  border: none;
  background-color: #6696E7;
  color: white;
  font-weight: 700;
  margin-right: 13px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

span.delete-btn-icon {
  margin-right: 5px;
}

span.delete-btn-icon svg {
  font-size: 20px;
  font-weight: 500;
}

.no-document-upload-box img {
  width: 35px;
  display: flex;
  justify-content: center;
  margin: 11px auto;
  margin-bottom: 12px;
}

.form-step3-main-cover-wrapper {
  /* width: 90%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h5.header-claim-title {
  font-weight: 800;
  font-family: "Lora", serif;
  font-size: 25px;
}

.view-document-btn {
  margin-bottom: 30px;
}


@media only screen and (min-width: 320px) and (max-width: 479px) {
  .stepper_main_wrapper {
    margin: 0px;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 25px;
    flex-wrap: wrap;
    width: 97%;
}
  .step_claim {
    margin-bottom: 15px;
    margin-top: 10px;
    transform: scale(0.9) !important;
}

.step_claim.active-step-claim {
  transform: scale(1);
}
.claims-main-cover-wrappper {
  width: 100%;
}
.claim-details-main-cover-wrapper {
  width: 90%;
  margin: 0 auto;
}
.home-claim-kyc-main-cover {
  width: 95%;
  margin: 0 auto;
}
  .kyc-input-box select {
    width: 100%;
    margin-bottom: 12px;
  }
  .upload-kyc-btn-box button {
    width: 100%;
}
  .footer-buttons button {
    height: 40px;
  }
}

